import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@components/common/Modal/Modal';
import { CvDetailHub, CvDetailHubProps } from '../CvDetailHub';


// Interface
interface CvModalTalentSearchProps extends Omit<CvDetailHubProps, 'closeModal'> {
  slug: string,
  jobSlug?: string,
  isAuthenticated?: boolean,
}


/**
 * @description CV Detail page. Used in the Talent Search context.
 * - **slug** - string - The CV slug.
 * - **isAuthenticated** - boolean - If the user is authenticated.
 */
export const CvModalTalent = NiceModal.create((props: CvModalTalentSearchProps) => {
  const {
    slug,
    jobSlug,
    isAuthenticated,
  } = props;

  // Use a hook to manage the modal state
  const modal = useModal();

  // Close modal
  const closeModal = () => {
    void modal.hide();
  };

  // Render component
  // *****************************************
  return (
    <Modal
      isOpen={modal.visible}
      onClose={closeModal}
      size="full"
      body={<CvDetailHub slug={slug} jobSlug={jobSlug} isAuthenticated={isAuthenticated} closeModal={closeModal} />}
      className="!transform-none !overflow-visible"
    />
  );
});


/**
 * @description Opens the CV in a modal. This is for opening a single CV, if you want to open a CV in a list with
 * navigation, take a look at the TalentList component.
 * @param slug - string - The CV slug. Optional.
 * @param jobSlug - string - The job slug. Optional.
 * @param isAuthenticated - boolean - If the user is authenticated. Optional.
 *
 * @example
 * showCvModalTalent('cv-slug', true);
 */
export const showCvModalTalent = (slug?: string, jobSlug?: string, isAuthenticated?: boolean) => {
  void NiceModal.show(CvModalTalent, { slug, jobSlug, isAuthenticated });
};
