import { useTranslation } from 'next-i18next';
import { CheckIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Button, Modal } from '@components/common';
import { IDeleteModalProps } from '@type/modal-types';


/**
 * @description Delete modal component you can use to confirm deletion of an item
 *
 * @example
 * <DeleteModal isOpen={true} setIsOpen={(val) => setIsOpen(val)} onDelete={() => {console.log("delete"); setIsOpen(true)}} />
 *
 * @param onDelete
 * @param isOpen
 * @param setIsOpen
 * @param description
 * @param cancelText
 * @param confirmText
 * @constructor
 */
export const DeleteModal = ({
  onDelete, isOpen, setIsOpen, description, cancelText, confirmText,
}: IDeleteModalProps) => {
  const { t } = useTranslation('common');

  const onClose = () => {
    setIsOpen(false);
  };

  const cancelTextToDisplay = cancelText || t('global.action.no');
  const confirmTextToDisplay = confirmText || t('global.action.yes');

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      body={(
        <div className="flex flex-col gap-6 p-4">
          <TrashIcon className="size-10 text-error" />
          <p>{description}</p>
          <div className="flex flex-wrap-reverse gap-4">
            <Button size="sm" styling="outline" color="light" onClick={() => onClose()}>{cancelTextToDisplay}</Button>
            <Button size="sm" color="error" onClick={onDelete} className="flex gap-2">
              <CheckIcon className="size-4" />
              {confirmTextToDisplay}
            </Button>
          </div>
        </div>
      )}
      onClose={() => setIsOpen(false)}
      centered={false}
      className="rounded-lg"
    />
  );
};
