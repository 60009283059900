import { Input } from '@components/common/Forms';
import { ChangeEvent, ReactNode } from 'react';

interface IProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  socialName: string;
  socialIcon: ReactNode;
  errorMessage?: string;
  pattern?: string;
}

/**
 * @description Component used for adding social links for: github, linkedin and professional website
 * @param value - input value
 * @param onChange - setter for the input field
 * @param placeholder
 * @param socialName - social name
 * @param socialIcon - social icon components
 * @param errorMessage - error message
 * @param pattern - regex pattern
 * @constructor
 */
export const SocialItemInput = ({
  value, onChange, placeholder, socialName, socialIcon, errorMessage, pattern,
}: IProps) => {
  const props = pattern ? { pattern } : {};

  return (
    <div className="mb-4">
      <div className="flex flex-wrap sm:flex-nowrap">
        <div className="order-2 w-full sm:order-1 sm:w-3/4">
          <Input
            type="url"
            className="text-sm"
            required={value?.length > 0}
            patternMismatchMessage={errorMessage}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange(e);
            }}
            {...props}
          />
        </div>
        <div className="order-1 mb-2 flex w-full items-center sm:order-2 sm:mb-0 sm:w-1/4 sm:pl-4">
          <div className="flex size-10 items-center justify-center rounded-full bg-surface-100">
            {socialIcon}
          </div>
          <div className="hidden">
            {socialName}
          </div>
        </div>
      </div>
    </div>
  );
};
