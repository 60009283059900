import { Button } from '@components/common';
import { TrashIcon } from '@heroicons/react/24/outline';
import { DeleteModal } from '@components/features/modals/DeleteModal';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'next-i18next';
import { IDeleteModalProps } from '@type/modal-types';

interface IProps {
  isSaveLoading: boolean;
  // if not set, uses submit from form
  onSubmit?: () => void;
  isDeleteLoading?: boolean;
  deleteModalProps?: IDeleteModalProps
  className?: string
}
export const FooterButtonsSaveDelete = ({
  isSaveLoading,
  onSubmit,
  isDeleteLoading,
  deleteModalProps,
  className = '',
}: IProps) => {
  const { t } = useTranslation();

  const submitExtraProps = onSubmit ? { onClick: onSubmit } : {};

  return (
    <div className={`flex w-full flex-col-reverse sm:flex-row ${deleteModalProps ? 'sm:justify-between' : 'justify-center'} ml-auto mt-6 min-w-full gap-4 border-t pt-4 sm:gap-2 ${className}`}>
      {deleteModalProps && (
        <>
          <Button color="error" className="flex gap-2 border-0 bg-error/15" styling="outline" rounding="full" isLoading={!!isDeleteLoading} onClick={() => { deleteModalProps.setIsOpen(true); }}>
            <TrashIcon className="size-5" />
            {t('global.action.delete')}
          </Button>
          <DeleteModal
            {...deleteModalProps}
          />
        </>
      )}
      <Button color="secondary" className="flex gap-2" type="submit" rounding="full" isLoading={isSaveLoading} {...submitExtraProps}>
        <CheckIcon className="size-5" />
        {t('global.action.save')}
      </Button>
    </div>
  );
};
